import React, {useEffect} from 'react';
// import axios from 'axios';
import * as am5 from "@amcharts/amcharts5";
// import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
const Piechart = (props) => {
  // const cycle=props.cycle;
  // const data1=props.data1;
  // const progress=props.progress;
    useEffect(() => {
      
        let root = am5.Root.new("chartdiv1");
        root.setThemes([
          am5themes_Animated.new(root)
        ]);
        
        let chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            radius: am5.percent(90),
            innerRadius: am5.percent(70)
          })
        );
        let series2 = chart.series.push(
          am5percent.PieSeries.new(root, {
            name: "Series",
            valueField: "sales",
            categoryField: "going"
          })
        );
        
        series2.data.setAll([{
          going: "progress",
          sales: props.progress
        }, {
          going: "left",
          sales: 100-props.progress,
          sliceSettings: { fill: am5.color(0xffffff) }
        }]);
        
        // Configuring slices
        series2.slices.template.setAll({
          stroke: am5.color(0x4099ff),
          strokeWidth: 2,
        
        })
        
        // Disabling labels and ticks
        series2.labels.template.set("visible", false);
        series2.ticks.template.set("visible", false);
        // Create series
        let series = chart.series.push(
          am5percent.PieSeries.new(root, {
            name: "Series",
            valueField: "sales",
            categoryField: "cycle"
          })
        );
        
        series.data.setAll([{
          cycle: "progress",
          sales: props.cycle
        }, {
          cycle: "left",
          sales: 664-props.cycle,
          sliceSettings: { fill: am5.color(0xffffff) }
        }]);
        
        // Configuring slices
        series.slices.template.setAll({
          stroke: am5.color(0xffffff),
          strokeWidth: 2,
        //   fill:am5.color(0xf0b409)
        })
        
        // Disabling labels and ticks
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);
        
        
      chart.appear(1000, 100);
      return () => {
        root.dispose();
      };
    }, []);
  
    return (
      <div style={{position:"relative"}}>
      <div  className='p-1 pl-10' id="chartdiv1" style={{ width: "160px", height: "150px" }}></div>
      <div className='text-gray-499 text-3xl font-bold' style={{position:'absolute', top:"57px", left:"73px"}}>{props.cycle}</div>
      </div>
    );
  };
  
  export default Piechart;