import logo from './logo.svg';
import './App.css';
import Dashboard from './components/dashboard';
import { DAppClient } from "@airgap/beacon-sdk";

// const dAppClient = new DAppClient({ name: "Beacon Docs" });

// try {
//   console.log("Requesting permissions...");
//   const permissions = await dAppClient.requestPermissions();
//   console.log("Got permissions:", permissions.address);
// } catch (error) {
//   console.log("Got error:", error);
// }
function App() {
  return (
    <div className="App">
      <Dashboard/>
    </div>
  );
}

export default App;
