import React, {useEffect} from 'react';
// import axios from 'axios';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
const Linechart = (props) => {
  
    useEffect(() => {
      
      let root = am5.Root.new("chartdiv");
  
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
      root.dateFormatter.setAll({
        dateFormat: "yyyy",
        dateFields: ["valueX"]
      });
      let chart = root.container.children.push( 
        am5xy.XYChart.new(root, {
          focusable: true,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX:true
        }) 
      );
  
      // Define data
      const data = props.chartdata;
        // console.log(props.chartdata)
      // let easing = am5.ease.linear;
      // Create Y-axis
      
  
      let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {
      
        }),
        tooltip: am5.Tooltip.new(root, {
          // labelText:"{valueX}"
        })
      }));
      
      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));
      
      
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
        minBulletDistance: 5,
        connect: false,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color('#59a5fb'),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "Balance: {valueY} XTZ"
        })
      }));
      
      series.fills.template.setAll({
        fillOpacity: 0.5,
        fill:am5.color('#59a5fb'),
        visible: true
      });
      series.set("fill", am5.color('#59a5fb'));
      series.strokes.template.setAll({
        strokeWidth: 2
      });
      
      
      // Set up data processor to parse string dates
      // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"]
      });
      
      series.data.setAll(data);
      // series.bullets.push(function() {
      //   var circle = am5.Circle.new(root, {
      //     radius: 4,
      //     fill: root.interfaceColors.get("background"),
      //     stroke: series.get("fill"),
      //     strokeWidth: 2
      //   })
      
      //   return am5.Bullet.new(root, {
      //     sprite: circle
      //   })
      // });
      series.strokes.template.setAll({
        strokeWidth: 3,
        // stroke: am5.color('#f00'),
        shadowColor: am5.color(0x000000),
        shadowBlur: 10,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        shadowOpacity: 0.5
      });
      
      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: root.interfaceColors.get("background"),
            stroke: am5.color('#59a5fb'),
            strokeWidth: 2,
            shadowColor: am5.color(0x000000),
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            shadowOpacity: 0.3
          })
        })
      });
      
      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none"
      }));
      cursor.lineY.set("visible", false);
      
      // add scrollbar
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));
      
      
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      return () => {
        root.dispose();
      };
    }, []);
  
    return (
      
      <div id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
      
    );
  };
  
  export default Linechart;