import React from 'react'
  
const Progress_bar = (props) => {
    const width = props.progress>100? 100:props.progress;
    const Parentdiv = {
        height: 10,
        width: '100%',
        backgroundColor: 'lightgray',
        borderRadius: 40,
        marginTop: 25
      }
      
      const Childdiv = {
        height: '100%',
        width: `${width}%`,
        backgroundColor: props.bgcolor,
       borderRadius:40,
        textAlign: 'right'
      }
      
      const progresstext = {
        padding: 0,
        color: 'black',
        width:20,
        backgroundColor: 'white',
        fontWeight: 900
      }
        
    return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        {/* <span style={progresstext}>{`${props.progress}%`}</span> */}
      </div>
    </div>
    )
}
  
export default Progress_bar;