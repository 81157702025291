// DataList.js
import React, {useState, useEffect} from 'react';
import { DotLoader } from 'react-spinners';
import axios from 'axios';
// import * as am5 from "@amcharts/amcharts5";
// import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Linechart from '../components/amchart/Linechart.js';
import Piechart from './amchart/Piechart.js';
import Progress_bar from './amchart/Progress_bar.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCube, faFileInvoiceDollar, faArrowsSpin, faMapLocation, faHeartPulse, faDropletSlash, faFire, faChartLine, faBoxesPacking, faGifts, faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
// import { Axis } from '@amcharts/amcharts5/xy.js';

import { DAppClient, TezosOperationType } from "@airgap/beacon-sdk";
const address = "tz1c8JTzmA6c7cm6ZmUwR7WES6akdiMa1kwt"; // Replace with baker address
const bakerName = "Bäckeranlage"; // Replace with baker name / website
const dAppClient = new DAppClient({ name: bakerName });

const Dashboard = () => {
  const [accountData, setAccountData] = useState(null);
  const [explorerAccountData, setExplorerAccountData] = useState(null);
  const [balanceData, setBalanceData] = useState(null);
  const [currentCycleData, setCurrentCycleData] = useState(null);
  const [endorsementData, setEndorsementData] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [incomeArrayData, setIncomeArrayData] = useState(null);
  
  const [t_blocks_baked,setBlocksBaked] = useState(null);
  const [t_blocks_proposed,setBlocksProposed] = useState(null);

  const [t_total_income,setTotalIncome] = useState(null);
  const [t_total_rewards,setTotalRewards] = useState(null);
  const [t_slots_endorsed,setSlotsEndorsed] = useState(null);
  const [t_luck,setLuck] = useState(null);
  const [t_performance,setPerformance] = useState(null);
  const [t_reliability,setReliability] = useState(null);

  const [connected, setConnected] = useState("Connect Wallet");

  const nowYear = new Date().getUTCFullYear();
  const nowDate = new Date();
  // let t_blocks_baked = 0;
  
  // const data1=[{
  //   country: "France",
  //   sales: 900
  // }, {
  //   country: "Spain",
  //   sales: 100,
  //   sliceSettings: { fill: am5.color(0xffffff) }
  // }];
  // let progress;
  // console.log(nowYear);
  // const walletConnect = async ()=>{
  //   try {
  //     console.log("Requesting permissions...");
  //     const permissions = await dAppClient.requestPermissions();
  //     console.log("Got permissions:", permissions.address);
  //   } catch (error) {
  //     console.log("Got error:", error);
  //   }
  // }
  const fetchAccountData = async () => {
    const response_account = await axios.get(`https://api.tzstats.com/explorer/bakers/${address}`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
    }});
    // const data_account = await response_account.json();
    const data_account = response_account.data;
    setAccountData(data_account);
    // console.log("-----------fetchAccountData---------------"+response_account.data["staking_balance"]);
  };
  const fetchExplorerAccountData = async () => {
    const response_explorerAccount = await axios.get(`https://api.tzstats.com/explorer/account/${address}`,{mode: 'no-cors',headers: {
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': '*',
    },});
    // const data_explorerAccount = await response_explorerAccount.json();
    const data_explorerAccount = response_explorerAccount.data;

    setExplorerAccountData(data_explorerAccount);
    // console.log("-----------fetchExplorerAccountData---------------");
  };
  const fetchBalanceData = async () => {
    const response_balance = await axios.get(`https://api.tzstats.com/series/balance?start_date=${nowYear}&collapse=1d&address=${address}`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': '*',
    }});
    // const data_balance = await response_balance.json();
    const data_balance = response_balance.data;

    // setBalanceData(data_balance);
    // console.log("-----------fetchBalanceData---------------");
    const loop=[];

    const temp = (a) => {
      let tempa=data_balance[a][0];
      let tempb=data_balance[a+1][0];
      while(tempa<tempb){
        loop.push({"date":tempa,"value":data_balance[a][1]});
        
        tempa=tempa+86400000;
      }
    }
    for(let i=0; i<data_balance.length-1;i++){
      temp(i);
    }
    setBalanceData(loop);

  };
  const fetchCurrentCycleData = async () => {
    const response_currentCycle = await axios.get(`https://api.tzstats.com/explorer/cycle/head`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': '*',
    }});
    // const data_currentCycle = await response_currentCycle.json();
    const data_currentCycle = response_currentCycle.data;

    setCurrentCycleData(data_currentCycle);
    // console.log("-----------fetchCurrentCycleData---------------");
  };
  const fetchEndorsementData = async () => {
    const response_endorsement = await axios.get(`https://api.tzstats.com/explorer/bakers/${address}/endorsements`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': '*',
    }});
    // const data_endorsement = await response_endorsement.json();
    const data_endorsement = response_endorsement.data;

    const array_data=[];
    data_endorsement.map((item) => {
      array_data.push(item["height"]);
    });
    setEndorsementData(array_data[0]);
    // console.log("-----------fetchEndorsementData---------------");
    
  };
  const fetchPriceData = async () => {
    const response_price = await axios.get(`https://api.tzstats.com/markets/tickers`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': '*',
    }});
    // const data_price = await response_price.json();
    const data_price = response_price.data;
    
    // const usd_price={};
    data_price.map((item) => {
      if(item["pair"]==="XTZ_USD"&&item["exchange"]==="coinbasepro"){
        setPriceData(item);
        // console.log(item["change"]);
      }
    });
    // console.log("-----------fetchPriceData---------------");
    
  };
  const fetchIncomeData = async () => {
    
    const response_income = await axios.get(`https://api.tzstats.com/tables/income?address=${address}`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': '*',
    }});
    // const data_income = await response_income.json();
    const data_income = response_income.data;

    setIncomeArrayData(data_income);
    const response_currentCycle = await axios.get(`https://api.tzstats.com/explorer/cycle/head`,{mode: 'no-cors',header: {
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Headers': '*',
    }});
    // const data_currentCycle = await response_currentCycle.json();
    const data_currentCycle = response_currentCycle.data;

    let blocks_baked = 0;
    let blocks_proposed = 0;
    let total_income = 0;
    let total_rewards = 0;
    let slots_endorsed = 0;
    let luck = 0; //11
    let performance = 0; //13
    let reliability = 0; //12
    // let p_total = 0;
    // let p_expected = 0;
    data_income.map((item)=>{
      blocks_baked = blocks_baked + item[14];
      blocks_proposed = blocks_proposed + item[15];
      total_income = total_income + item[22];
      total_rewards = total_rewards + item[24] + item[25];
      slots_endorsed = slots_endorsed + item[19];
      // console.log(data_currentCycle["cycle"]);
      if(item[1]>=data_currentCycle["cycle"]-64&&item[1]<data_currentCycle["cycle"]){
        // p_expected = p_expected + item[21];
        // p_total = p_total + item[22];
        performance = performance + item[13];
        reliability = reliability + item[12];
        luck = luck + item[11];
      }
      
      // performance = performance/64;
    })
    // luck = luck/64;
    // performance = p_total/p_expected;
    performance = performance/64;
    reliability = reliability/64;
    luck = luck/64;
    
    // console.log(luck+"---------------luck------------");
    // console.log(performance+"---------------performance------------");
    // console.log(reliability+"---------------reliability------------");
    setBlocksBaked(blocks_baked);
    setBlocksProposed(blocks_proposed);
    setTotalIncome(Math.round(total_income*1000000)/1000000);
    setTotalRewards(Math.round(total_rewards*1000000)/1000000);
    setSlotsEndorsed(Math.round(slots_endorsed*1000000)/1000000);
    setLuck(Math.round(luck*100)/100); //11
    setPerformance(Math.round(performance*100)/100); 
    setReliability(Math.round(reliability*100)/100); 
  }

  const delegate = async() => {
    try{await dAppClient.requestOperation({
      operationDetails: [
        {
          kind: TezosOperationType.DELEGATION,
          delegate: address,
        },
      ],
    })}
    catch(error){

    }
  };
  const walletConnect = async ()=>{
    const activeAccount = await dAppClient.getActiveAccount();
    if (activeAccount) {
      // console.log("Already connected:", activeAccount.address);
      document.getElementById("walletDisconnect").style.display="block";
      setConnected(activeAccount.address);
      return activeAccount;
    } else {
      const permissions = await dAppClient.requestPermissions();
      // console.log("New connection:", permissions.address);
      document.getElementById("walletDisconnect").style.display="block";
      setConnected(permissions.address);
      return permissions;
      
    }
    
  }
  
  const walletDisconnect = async () => {    
    dAppClient.clearActiveAccount().then(async () => {
      const account = await dAppClient.getActiveAccount();
      setConnected("Connect Wallet");
      document.getElementById("walletDisconnect").style.display="none";
      // console.log("Active Account", account);
    });
  }
  const delegation_click= async () => {
    // Check if we have an active account
    const activeAccount = await dAppClient.getActiveAccount();
      if (activeAccount) {
        document.getElementById("walletDisconnect").style.display="block";
        setConnected(activeAccount.address);
        // If we have an active account, send the delegate operation directly
        delegate();
      } else {
        // If we don't have an active account, we need to request permissions first and then send the delegate operation
        dAppClient.requestPermissions().then((permissions) => {
          document.getElementById("walletDisconnect").style.display="block";
          setConnected(permissions.address);
          delegate();
        });
      }
  }
  const walletDataShow = async() => {
    const activeAccount = await dAppClient.getActiveAccount();
    if(activeAccount){
      setConnected(activeAccount.address);
      if(document.getElementById("walletDisconnect")){document.getElementById("walletDisconnect").style.display="block";}
    }
    else{
      setConnected("Connect Wallet");
      if(document.getElementById("walletDisconnect")){document.getElementById("walletDisconnect").style.display="none";}
    }
  }
  useEffect(() => {
    
    fetchAccountData();
    fetchExplorerAccountData();
    fetchBalanceData();
    fetchCurrentCycleData();
    fetchEndorsementData();
    fetchPriceData();
    fetchIncomeData();
    walletDataShow();
    
  }, [address,nowYear]);
  // setInterval(() => setEndorsementData(), 5000)
  
  // console.log(endorsementData);
  // if(balanceData){
  //   balanceData.map((item) => {
  //     loop.push({"date":item[0],"value":item[1]});
  //   });
  // }
  
  // if(balanceData){
  //   console.log(balanceData);
  //   const temp = (a) => {
  //     let tempa=balanceData[a][0];
  //     let tempb=balanceData[a+1][0];
  //     while(tempa<tempb){
  //       loop.push({"date":tempa,"value":balanceData[a][1]});
        
  //       tempa=tempa+86400000;
  //     }
  //   }
  //   for(let i=0; i<balanceData.length-2;i++){
  //     temp(i);
  //   }
  // }
  
  // if(currentCycleData){
  //   progress=currentCycleData["progress"];
    
  // }
  
  
  
  if (!accountData||!explorerAccountData||!currentCycleData||!balanceData||!endorsementData||!priceData||!incomeArrayData||!t_blocks_baked||!t_total_income) {
    return <div className="flex flex-row gap-8 py-50 justify-center items-center  w-full h-screen" >
    <DotLoader/>
    <div>Loading...</div>
    {/* <img className='back-gif' src="img/photo_2023-06-15_01-47-46.jpg" alt="ad"></img> */}
</div>
  }
  
  // Define data
  
  // const text = "tz1c8JTzmA6c7cm6ZmUwR7WES6akdiMa1kwt";
    
  setInterval(() => fetchEndorsementData(), 50000)
  setInterval(() => fetchPriceData(), 100000)
  // setInterval(() => fetchCurrentCycleData(), 50000)

  return (
    <div>
      <div className='header-navbar'>
        <div className='nav-left flex'><img className='headerLogo' src="img/Tezos XTZ Icon.png" alt="ad"></img>Tezos Stats</div>
        <div className='nav-right flex'>
          <div id="walletConnect" onClick={walletConnect} className='btn'>{connected}</div>
          <div id="walletDisconnect" onClick={walletDisconnect} className='btn'>Disconnect</div>
        </div>
      </div>
      <div className="main-container">
        <div className='page-header flex pl-10 pr-10 pb-5 pt-5'>
          <div className='flex w-3/5'>
            <div className='mx-4'><img className='tezosLogo' src="img/xtz-x2.png" alt="ad"></img></div>
            <div>
              <div className='text-4xl font-stronger text-left font-bold'>Bäckeranlage</div>
              <div>tz1c8JTzmA6c7cm6ZmUwR7WES6akdiMa1kwt 
              <button onClick={() => {navigator.clipboard.writeText(address);}}> 📋︎</button>
              </div>
            </div>
          </div>
          <div className='flex'>
            <div className='header-li flex text-blue-400'>
              <div className=' text-4xl pr-2 '> <FontAwesomeIcon icon={faMapLocation} /> </div>
              <div>
                <div>Location</div>
                <div className='text-2xl font-bold text-gray-400'>Zurich</div>
              </div>
            </div>
            <div className='header-li flex text-green-400'>
              <div className=' text-4xl pr-2 '> <FontAwesomeIcon icon={faDropletSlash} /> </div>
              <div>
                <div>Reliability</div>
                <div className='text-2xl font-bold text-gray-400'>{t_reliability}</div>
              </div>
            </div>
            <div className='header-li flex text-orange-400'>
            <div className=' text-4xl pr-1 '> <FontAwesomeIcon icon={faFire} /> </div>
              <div>
              {/* <div className=' text-4xl pr-1'> <FontAwesomeIcon icon={faFire} /> </div> */}
                <div >Commision</div>
                <div className='text-2xl font-bold text-gray-400'>8%</div>
              </div>
            </div>
            <div className='header-balance' >
              {/* <div className='flex' > */}
              <div className='left'>Staking Balance</div>
                <div className='right'>Free Capacity</div>
              {/* </div> */}
              <div>
                <Progress_bar bgcolor="Blue" progress={(accountData["staking_balance"]/accountData["staking_capacity"])*100}/>
              </div>
              <div>
              <div className='left'>{Math.round(accountData["staking_balance"]).toLocaleString() }XTZ</div>
                <div className='right'>{Math.round(accountData["staking_capacity"] - accountData["staking_balance"]).toLocaleString() }XTZ</div>
              </div>
            </div>
            <div className='header-li' style={{paddingRight:'0px', border:'none'}}>
            <div className='btn' style={{marginLeft:"35px"}} onClick={delegation_click}>Delegate</div>
            </div>
          </div>
        
        </div>
        
      </div>
      <div className='page-main flex mx-8 mb-8'>
          <div className='bg-white w-2/3 mr-4 p-8 rounded-md shadow-md' >
            <div className='text-4xl text-gray-400 font-bold'><FontAwesomeIcon icon={faChartLine} /> Balance</div>
        <Linechart chartdata={balanceData}></Linechart>
            
          </div>
          <div className='bg-white w-1/3 ml-4 p-8 rounded-md shadow-md'>
            <div className='text-4xl font-bold text-gray-400'><FontAwesomeIcon icon={faHeartPulse} /> Lifetime Statistics</div>
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Baker Since</div>
              <div className='text-right w-1/2'>{accountData["baker_since"]}</div>
            </div>
            <hr></hr>    
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Days Active</div>
              <div className='text-right w-1/2'>{Math.round((new Date().getTime()-new Date(accountData["baker_since"]).getTime())/86400000)}</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Received</div>
              <div className='text-right w-1/2'>{explorerAccountData["total_received"].toLocaleString()}XTZ</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Sent</div>
              <div className='text-right w-1/2'>{explorerAccountData["total_sent"].toLocaleString()}XTZ</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Burned</div>
              <div className='text-right w-1/2'>{explorerAccountData["total_burned"]}XTZ</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Fees Paid</div>
              <div className='text-right w-1/2'>{explorerAccountData["total_fees_paid"]}XTZ</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Income</div>
              <div className='text-right w-1/2'>{t_total_income.toLocaleString()} XTZ</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Rewards</div>
              <div className='text-right w-1/2'>{t_total_rewards.toLocaleString()} XTZ</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Fees</div>
              <div className='text-right w-1/2'>{(t_total_income - t_total_rewards).toLocaleString()} XTZ</div>
            </div>
            <hr></hr>           
            
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Total Slashed</div>
              <div className='text-right w-1/2'>0 XTZ</div>
            </div>
            <hr></hr>   
            
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Blocks Baked</div>
              <div className='text-right w-1/2'>{t_blocks_baked}</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Blocks Proposed</div>
              <div className='text-right w-1/2'>{t_blocks_proposed}</div>
            </div>
            <hr></hr>   
            <div className='flex pb-1 pt-1 pl-2 pr-2 hover:bg-gray-300 hover:font-bold'>
              <div className='text-left w-1/2'>Slots Endorsed</div>
              <div className='text-right w-1/2'>{t_slots_endorsed.toLocaleString()}</div>
            </div>
            <hr></hr>   
          </div>
      </div>
      <div className='page-main flex mx-8 mb-8'>
          <div className='bg-white w-1/3 mr-4 p-2 rounded-md shadow-md flex'>
            
            
            <Piechart cycle={currentCycleData["cycle"]} progress={currentCycleData["progress"]}></Piechart>
            <div className='w-1/3 pt-7 text-blue-400'>
            <div className='text-5xl'><FontAwesomeIcon icon={faArrowsSpin} /></div>
            <div>Cycle</div>
            <div className='text-2xl font-bold text-gray-400 hover:text-blues-400 hover:cursor-pointer' style={{overflow: "hidden"}}>
            {parseInt(((new Date(currentCycleData["end_time"]).getTime()-nowDate.getTime())/3600000)/24)}d:
            {parseInt(((new Date(currentCycleData["end_time"]).getTime()-nowDate.getTime())/3600000)%24)}h:
            {parseInt(((new Date(currentCycleData["end_time"]).getTime()-nowDate.getTime())/60000)%60)}m</div>
            </div>
            {/* <div className="w-1/2 p-5">
              <div className='flex'>
                <div className='p-5'>qwe</div>
                <Progress_bar  bgcolor="#ffb64d" progress='30'  height={10} />
              </div>
              <div className='flex'>
                <div className='p-5'>qwe</div>
                <Progress_bar  bgcolor="#ffb64d" progress='30'  height={10} />
              </div>
            </div> */}
            <div className="w-1/4 pt-7 text-green-400">
              <div className='text-5xl '><FontAwesomeIcon icon={faCube} /></div>
              <div>Block</div>
              <div className='text-2xl font-bold text-gray-400 hover:text-green-400 hover:cursor-pointer'>{endorsementData.toLocaleString()}</div>
            </div>
            <div className="w-1/4 pt-7 text-orange-400">
              <div className='text-5xl '><FontAwesomeIcon icon={faFileInvoiceDollar} /></div>
              <div>{Math.round(priceData["change"] * 100) / 100}%</div>
              <div className='text-2xl font-bold text-gray-400 hover:text-orange-400 hover:cursor-pointer'>{priceData["last"]}</div>
            </div>
          </div>
          <div className='bg-white w-2/3 ml-4 p-8 rounded-md shadow-md flex'>
          <div className='flex pb-1 pt-1 w-1/3'>
              <div className='w-11/12' >
                <div className='flex'>
                  <div className='w-2/3'>
                    <div className='text-left font-bold text-gray-500'>Avg. Luck (64 Cycles)</div>                
                    <div className='text-left text-2xl font-bold text-gray-400'>{t_luck} %</div>
                  </div>
                  <div className='text-right w-1/3 text-blue-300 text-5xl'><FontAwesomeIcon icon={faGifts} /></div>
                </div>
                <div>
                  <Progress_bar bgcolor="#60a5fa" progress={t_luck}  />
                </div>
              </div>
            </div>
            <div className='flex pb-1 pt-1 w-1/3'>
              <div className='w-11/12 ' >
                <div className='flex'>
                  <div className='w-2/3 '>
                    <div className='text-left font-bold text-gray-500' >Avg. Performance (64 Cycles)</div>                
                    <div className='text-left text-2xl font-bold text-gray-400'>{t_performance} %</div>
                  </div>
                  <div className='text-right w-1/3 text-green-300 text-5xl'><FontAwesomeIcon icon={faBoxesPacking} /></div>
                </div>
                <div>
                  <Progress_bar bgcolor="#4ade80" progress={t_performance} />
                </div>
              </div>
            </div>
            <div className='flex pb-1 pt-1 w-1/3'>
              <div className='w-11/12' >
                <div className='flex'>
                  <div className='w-2/3'>
                    <div className='text-left font-bold text-gray-500'>Avg. Reliability (64 Cycles)</div>                
                    <div className='text-left text-2xl font-bold text-gray-400'>{t_reliability} %</div>
                  </div>
                  <div className='text-right w-1/3 text-orange-300 text-5xl'><FontAwesomeIcon icon={faBoxesStacked} /></div>
                </div>
                <div>
                  <Progress_bar bgcolor="#fb923c" progress={t_reliability}  />
                </div>
              </div>
            </div>
          </div>

      </div>
    {/* <Linechart chartdata={chartdata}></Linechart> */}
    </div>
  );
};

export default Dashboard;